export default {
  data() {
    return {
      timeWatch: 0,
      interval: false,
      currentTimeWatch: 0
    };
  },
  methods: {
    startTimeWatch() {
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.timeWatch++;
        }, 1000);
      }
    },
    stopTimeWatch() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = false;
      }
    }
  }
};
