<template>
  <div class="player-prevent" :style="styles">
    <slot />

    <transition name="message">
      <div v-if="prevented" class="player-prevent__message">
        {{ $t("general.prevent_video_message") }}
      </div>
    </transition>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    borderRadius: {
      type: String,
      default: ""
    },
    playing: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prevented: false,
      fullscreened: false,
      playingOnPrevent: false
    };
  },
  computed: {
    styles() {
      return {
        "border-radius": this.borderRadius
      };
    }
  },
  methods: {
    ...mapMutations("settings", {
      setPrevent: "SET_ENTITY"
    }),
    prevent() {
      this.playingOnPrevent = this.playing;
      this.fullscreened = this.fullscreen;

      this.prevented = true;
      this.$emit("update:fullscreen", false);
      this.$emit("prevent");
    },
    allow() {
      this.prevented = false;

      if (this.fullscreened) {
        this.$emit("update:fullscreen", true);
      }

      if (this.playingOnPrevent) {
        this.$emit("allow");
      }
    }
  },
  async mounted() {
    // const { data } = await this.axios.get("/api/discretes");
    //
    // if (data.pa) {
    //   this.setPrevent(["prevented", true]);
    //   this.prevent();
    // }
    //
    // this.$socket.$subscribe("discretes", payload => {
    //   console.log("discretes!!!!!", payload);
    //   if (payload.pa) {
    //     this.prevent();
    //     this.setPrevent(["prevented", true]);
    //   } else {
    //     this.allow();
    //     this.setPrevent(["prevented", false]);
    //   }
    // });
  },
  beforeDestroy() {
    // this.$socket.$unsubscribe("discretes");
  }
};
</script>

<style lang="sass" scoped>
.player-prevent
  position: relative

  @media (max-width: 767px)
    border-radius: 0 !important

  &__message
    display: flex
    align-items: center
    justify-content: center
    border-radius: inherit
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    background: rgba(0, 0, 0, 0.7)
    color: #FFFFFF
    backdrop-filter: blur(4px)
    z-index: 100000000
    font-size: 20px
    line-height: 24px
    text-align: center

.message-enter-active,
.message-leave-active
  transition: opacity 0.3s

.message-enter,
.message-leave-to
  opacity: 0
  backdrop-filter: blur(0)
</style>
